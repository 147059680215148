<template>

    <div>
        <v-container id="dashboard" fluid tag="section">
            <v-text-field class="mt-4" label="اكتب للبحث" outlined append-icon="mdi-magnify" v-model="search">
            </v-text-field>

            <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1" :items-per-page="10000">
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title> {{ $t("Drawer.newsletter") }} </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="exportData()" dark class="mb-2" >
                                    تحميل ملف اكسل
                                </v-btn>
                      
                    </v-toolbar>
                </template>

                <template v-slot:[`item.phonex`]="{ item }">





                <p style="direction:ltr">{{ item.phone}}</p>


                </template>
                <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
    import Axios from "axios";
      import Swal from "sweetalert2";
    export default {
        data() {
            return {
                desserts: [

                ],
                cats: [],
                dialog: false,
                loadSave: false,
                         imagesUrl: '',
                imageName: '',
                imageUrl:'',
                img_old: '',
                imageFile: '',
               
                imagesName: ' ',
                editedIndex: -1,
                editedItem: {
                    translations: [{
                            title: '',
                            description:""
                        },
                        {
                            title: '',
                            description:""
                        }
                    ],
                    id: "",
                    title: "",

                    description: "",

                    articles_cats_id: ""

                },
                items: [

                ],
                headers: [
                    {
                        text: '#id',
                        align: "start",
                        value: "id"
                    },
                     {
                        text: this.$t('phone'),
                        align: "right",
                        value: "phonex"
                    },
                    // {
                    //     text: this.$t('datatable.message'),
                    //     value: "message"
                    // },
                    //  {
                    //     text: this.$t('phone'),
                    //     value: "phone"
                    // },



                    // {
                    //     text: this.$t('Processes'),
                    //     value: "actions",
                    //     sortable: false
                    // }
                ],
                right: null
            }
        },
        mounted() {
         
      },
        methods: {
            exportData(){
                Axios({
    url: 'newsletter/export',
    method: 'GET',
    responseType: 'blob',
}).then((response) => {
     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
     var fileLink = document.createElement('a');
  
     fileLink.href = fileURL;
     fileLink.setAttribute('download', 'file.excel');
     document.body.appendChild(fileLink);
   
     fileLink.click();
});


                Axios({
                    url: 'newsletter/export', // File URL Goes Here
                    method: 'GET',
                    responseType: 'Blob',
                }).then((res) => {
                     var FILE = window.URL.createObjectURL(new Blob([res.data]));
                    
                     var docUrl = document.createElement('x');
                     docUrl.href = FILE;
                    
                     docUrl.setAttribute('download', 'file.excel');
                     document.body.appendChild(docUrl);
                     docUrl.click();
                });
                
                // Axios.get("newsletter/export", {
                //         headers: {
                //             "Content-Type": "application/json",
                //             Accept: "application/json",
                //             Authorizations: "Bearer " +this.$store.state.AdminInfo.token
                //         }
                //     })
                //     .then(res => {
                //         this.loading = false;
                //         this.desserts = res.data;
                //         //  console.log(res.data);
                //     })
                //     .catch(() => {
                //         this.loading = false;
                //     });


            },
            editItem(item) {
                this.editedIndex = this.desserts.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;

                //   if (this.imageUrl[0] == null) {

                    this.img_old = this.editedItem.img_file;
                    this.imageUrl = 'http://127.0.0.1:8000/art_img/thumb/' + this.img_old;
                 ///   this.thumb_small = 'https://alkafeel.net/women_library/photo/thumb_small/' + this.img_old;

              //  }
            },
            close() {
                this.dialog = false;
                // this.editedItem ={};
                this.editedItem = {
                    translations: [{
                            title: '',
                            description: ''
                        },
                        {
                            title: '',
                            description: ''
                        }
                    ],
                    id: "",
                    title: "",

                    description: "",

                    photo_gallery_cats_id: ""

                };
                // this.$nextTick(() => {
                //     this.editedItem = Object.assign({}, this.defaultItem);
                //     this.editedIndex = -1;
                // });
            },
            initialize() {
                this.loading = true;
                Axios.get("newsletter", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            Authorizations: "Bearer " +this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.desserts = res.data;
                        //  console.log(res.data);
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },


 pickImg() {
                this.$refs.image.click()
            },


            pickFiles() {
                this.$refs.images.click()

            },


            onFilePicked(e) {


                const files = e.target.files


                if (files[0] !== undefined) {



                    this.imageName = files[0].name


                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }


                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {

                        this.imageUrl = fr.result
                        this.imageFile = files[0]


                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''


                }
            },

  deleteItem(item) {
      
      
        Swal.fire({
          title: this.$t('sure_process'),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText:  this.$t('yes'),
          cancelButtonText: this.$t('no'),
        }).then(result => {
          if (result.value) {
            Axios.delete("articles/" + item.id, {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorizations: "Bearer " +this.$store.state.AdminInfo.token
                }
              })
              .then(() => {
                this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");
                this.initialize();
              })
              .catch(() => {
                this.$swal.fire( this.$t('not_successful'), this.$t('not_done'), "error");
              });
          }
        });
      },


          

           

        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('add_new') : this.$t('update');

            },
        },
        created() {
            this.initialize();
         //   this.getCats();
        },

    }
</script>